.features {
  .list {
    display: none;
  }
  &:hover {
    .list {
      display: flex;
    }
  }
}
